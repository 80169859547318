import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const ToppingsStyles = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 4rem;
	a {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 0 1rem;
		align-items: center;
		padding: 5px;
		background: var(--grey);
		border-radius: 2px;
		text-decoration: none;
		font-size: clamp(1.5rem, 1.4vw, 2.5rem);
		.count {
			background: white;
			padding: 2px 5px;
		}
		&[aria-current='page'] {
			background: var(--yellow);
		}
	}
`

function countPizzasInToppings(pizzas) {
	const counts = pizzas
		.map((pizza) => pizza.toppings)
		.flat()
		.reduce((acc, topping) => {
			const existingTopping = acc[topping.id]
			if (existingTopping) {
				existingTopping.count += 1
			} else {
				acc[topping.id] = {
					id: topping.id,
					name: topping.name,
					count: 1,
				}
			}
			return acc
		}, {})

	return Object.values(counts).sort((a, b) => {
		return b.count - a.count
	})
}

function ToppingsFilter() {
	const { toppings, pizzas } = useStaticQuery(graphql`
		query {
			toppings: allSanityTopping {
				nodes {
					name
					id
					vegetarian
				}
			}
			pizzas: allSanityPizza {
				nodes {
					toppings {
						name
						id
					}
				}
			}
		}
	`)

	const toppingsWithCounts = countPizzasInToppings(pizzas.nodes)

	return (
		<ToppingsStyles>
			{toppingsWithCounts.map((topping) => (
				<Link to={`/topping/${topping.name}`} id={topping.id}>
					<span className='name'>{topping.name}</span>
					<span className='count'>{topping.count}</span>
				</Link>
			))}
		</ToppingsStyles>
	)
}

export default ToppingsFilter
