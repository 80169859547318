import React from 'react'
import { graphql } from 'gatsby'
import PizzaList from '../components/PizzaList'
import ToppingsFilter from '../components/ToppingsFilter'

function PizzasPage({ data, pageContext }) {
	const pizzas = data.allSanityPizza.nodes
	return (
		<div>
			<ToppingsFilter activeTopping={pageContext.topping} />
			<PizzaList pizzas={pizzas} />
		</div>
	)
}

export default PizzasPage
/*export const query = graphql`
	query PizzaQuery {
		allSanityPizza {
			nodes {
				name
				id
				slug {
					current
				}
				toppings {
					id
					name
				}
				image {
					asset {
						fluid(maxWidth: 400) {
							...GatsbySanityImageFluid
						}
					}
				}
			}
		}
	}
`*/

export const query = graphql`
	query ($id: [String]) {
		allSanityPizza(filter: { toppings: { elemMatch: { id: { in: $id } } } }) {
			nodes {
				toppings {
					name
				}
				slug {
					current
				}
				id
				name
				image {
					asset {
						fixed(width: 600, height: 200) {
							...GatsbySanityImageFixed
						}
						fluid(maxWidth: 400) {
							...GatsbySanityImageFluid
						}
					}
				}
			}
		}
	}
`
